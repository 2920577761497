import styled from "styled-components"

const ButtonStyled = styled.div`
  width: 216px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => (props.position === "center" ? "margin: 0 auto;" : "")}
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    text-decoration: none;
    height: 100%;
    width: 100%;
    overflow: hidden;

    ${props =>
      props.color === "transparent"
        ? `transition: 0.2s all linear;`
        : "transition: 0.5s all linear;"};

    ${props =>
      props.color === "blue"
        ? `background: #0638D9; 
          color: #fff; 
          border: 2px solid #0638D9;`
        : ""};
    ${props =>
      props.color === "sea"
        ? `background: #05B7BA; 
          color: #fff;
          border: 2px solid #05B7BA;`
        : ""};
    ${props =>
      props.color === "transparent"
        ? `background: transparent; 
           color: #E0E0E0; 
           border: 2px solid #E0E0E0;`
        : ""};

    &:hover {
      ${props => (props.color === "transparent" ? `color: #fff;` : "")};
      span {
        transform: scale(1.05);
      }
      &:before {
        ${props =>
          props.color === "transparent"
            ? `transform: translate(0%);`
            : "transform: translate(100%);"};
      }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: translate(-100%);
      ${props => (props.hover_color === "blue" ? `background: #0638D9;` : "")};
      ${props => (props.hover_color === "sea" ? `background: #05B7BA;` : "")};
      ${props =>
        props.hover_color === "transparent" ? `background: #191C1D;` : ""};
      ${props =>
        props.color === "transparent"
          ? `transition: 0.25s all linear;`
          : "transition: 0.5s all linear;"};
    }
    span {
      transition: 0.3s transform;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      width: 100%;
      z-index: 20;
      padding: 0 25px;
      text-align: center;
    }
  }
`

export default ButtonStyled
