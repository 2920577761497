import React from "react"
import ButtonStyled from "./styles/ButtonStyles"
import { Link } from "gatsby-plugin-modal-routing"

const Button = ({ text, link, color, hover_color, position, modal }) => {
  const ScollToHash = e => {
    const clickedElement = e.currentTarget
    const regexHash = /#\w*/
    const hash = regexHash.exec(clickedElement.href)[0]
    if (hash && hash.length > 1) {
      e.preventDefault()
      const regexTitle = /=[A-z]*(%20)?[A-z]*/
      let title = regexTitle.exec(clickedElement.href)[0]
      title = title.substr(1)
      title = title.replace("%20", " ")
      const element = document.querySelector(hash)
      const formTitle = element.querySelector("input[name=title]")
      formTitle.value = "Szkolenie - " + title
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      })
    }
  }
  return (
    <ButtonStyled color={color} hover_color={hover_color} position={position}>
      {typeof window !== "undefined" ? (
        window.location.pathname.includes("oferta") ? (
          <Link
            to={link}
            onClick={e => {
              ScollToHash(e)
            }}
          >
            <span>{text}</span>
          </Link>
        ) : (
          <Link to={link} asModal={modal ? true : false}>
            <span>{text}</span>
          </Link>
        )
      ) : (
        <Link to={link} asModal={modal ? true : false}>
          <span>{text}</span>
        </Link>
      )}
    </ButtonStyled>
  )
}

export default Button
